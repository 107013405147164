import { defineAsyncComponent } from 'vue';

export default {
  DailyBetsProfile: defineAsyncComponent(() => import('./DailyBetsProfile.vue')),
  ContactForm: defineAsyncComponent(() => import('./ContactForm.vue')),
  BestBetsHistory: defineAsyncComponent(() => import('./BestBetsHistory.vue')),
  WinnersHistory: defineAsyncComponent(() => import('./WinnersHistory.vue')),
  BestLastMonthStats: defineAsyncComponent(() => import('./BestLastMonthStats.vue')),
  BestBetSeries: defineAsyncComponent(() => import('./BestBetSeries.vue')),
  ClassifiedInTop10: defineAsyncComponent(() => import('./ClassifiedInTop10.vue')),
  AwardedBets: defineAsyncComponent(() => import('./AwardedBets.vue')),
  CommentsBonuses: defineAsyncComponent(() => import('./CommentsBonuses.vue')),
  FightGalas: defineAsyncComponent(() => import('./FightGalas.vue')),
  Competitors: defineAsyncComponent(() => import('../components/commentary_tabs/Competitors.vue')),
	Polls: defineAsyncComponent(() => import('./Polls.vue')),
	Journalists: defineAsyncComponent(() => import('./Journalists.vue')),
  GalaSchedule: defineAsyncComponent(() => import('./GalaSchedule.vue')),
	FightSchedule: defineAsyncComponent(() => import('./FightSchedule.vue')),
	BetsDiscipline: defineAsyncComponent(() => import('./BetsDiscipline.vue')),
  BookmakersRanking: defineAsyncComponent(() => import('./BookmakersRanking.vue')),
  CurrentMonthBestBetSeries: defineAsyncComponent(() => import('./CurrentMonthBestBetSeries.vue')),
  BestBetsInLast7Days: defineAsyncComponent(() => import('./BestBetsInLast7Days.vue')),
	BonusWithoutDeposits: defineAsyncComponent(() => import('./BonusWithoutDeposits.vue')),
	FootballTable: defineAsyncComponent(() => import('./FootballTable.vue')),
  UserListBox: defineAsyncComponent(() => import('./UserListBox.vue')),
	FootballLeagueResults: defineAsyncComponent(() => import('./FootballLeagueResults.vue')),
	FootballLeagueTimetable: defineAsyncComponent(() => import('./FootballLeagueTimetable.vue')),
	FootballStatsCompareTeams: defineAsyncComponent(() => import('./FootballStatsCompareTeams.vue')),
	FootballAllFootballTables: defineAsyncComponent(() => import('./FootballAllFootballTables.vue')),
	FootballLeagueGroups: defineAsyncComponent(() => import('./FootballLeagueGroups.vue')),
	FootballLeagueNestedGroups: defineAsyncComponent(() => import('./FootballLeagueNestedGroups.vue')),
	FootballLeagueBestGlobalScorers: defineAsyncComponent(() => import('./FootballBestGlobalScorers.vue')),
  UserRankings: defineAsyncComponent(() => import('./UserRankings.vue')),
  TournamentQualifications: defineAsyncComponent(() => import('./TournamentQualifications.vue')),
}